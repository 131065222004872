.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.col{
  margin: 20px 0;
}

.correct{
  font-weight: bold;
}

.correct .form-check-input:checked{
  background-color: rgb(30, 202, 30);
    border-color: rgb(30, 202, 30);
}

.btn{
  margin: 5px;
}

.pw{
  display: flex;
  flex-wrap: wrap;

}

.p{
  width: 10px;
  height: 10px;
  background-color: #CCCCCC;  
}

.p.correct{
  background-color: rgb(30, 202, 30);
}

.p.wrong{
  background-color: #F70000;
}

.p.selected{
  border: 2px solid rgba(0,0,0,.4);

}

.p:hover{
  border: 2px solid rgba(0,0,0,.2);
}

.trash{
  display: none;
}

.card:hover .trash{
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
